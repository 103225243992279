import React, { useContext, useEffect, useState } from 'react';
import { SERVER_URL } from './config';
import { AppContext } from '../App';
import axios from 'axios';
import './Category.css';
import { Link } from 'react-router-dom';
import './ItemInfo.css';

function Category({ categoryProp }) {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const [isColorOptionsVisible, setIsColorOptionsVisible] = useState(false);
  const [isSizeOptionsVisible, setIsSizeOptionsVisible] = useState(false);
  const { setSelectedItem, selectedColors, setSelectedColors, selectedSizes, setSelectedSizes } = useContext(AppContext);
  const [images, setImages] = useState([]);

  const filters = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}filters`, {
        params: {
          color: selectedColors,
          size: selectedSizes,
          category: categoryProp,
        }
      });
      
     setImages(response.data);
    } catch (error) {
      console.error('Error getting clothes', error);
    }
  };

  useEffect(() => {
    setImages([]);
    setSelectedColors([]);
    setSelectedSizes([]);
    filters();
  }, [categoryProp]);

  const handleColorToggle = (color) => {
    if (selectedColors.includes(color)) {
      setSelectedColors(selectedColors.filter((c) => c !== color));
    } else {
      setSelectedColors([...selectedColors, color]);
    }
  };

  const handleSizeToggle = (size) => {
    if (selectedSizes.includes(size)) {
      setSelectedSizes(selectedSizes.filter((s) => s !== size));
    } else {
      setSelectedSizes([...selectedSizes, size]);
    }
  };

  const allColors = [
    'ורוד', 'ירוק', 'שחור', 'אפור', 'טורקיז', 'סגול', 'כחול', 'חום', 'אדום', 'אפור כהה',
    "בז'", 'תכלת', 'ירוק זית', 'צהוב', 'כתום', 'לבן'
  ];

  return (
    <div className="container">
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
          {categoryProp !== 'sale' && (
            <>
              <button className="filter-btn" onClick={() => setIsFilterMenuOpen(true)}>
                סינון
              </button>
              {isFilterMenuOpen && (
                <div className="filter-menu">
                  <button className="close-btn" onClick={() => setIsFilterMenuOpen(false)}>
                    X
                  </button>
                  <div>
                    <br />
                    <button onClick={() => setIsColorOptionsVisible(!isColorOptionsVisible)}>סנן לפי צבע</button>
                    {isColorOptionsVisible && (
                      <>
                        {allColors.map((color) => (
                          <label key={color}>
                            <input
                              type="checkbox"
                              checked={selectedColors.includes(color)}
                              onChange={() => handleColorToggle(color)}
                            />
                            {color}
                          </label>
                        ))}
                      </>
                    )}
                  </div>
                  <div>
                    <button onClick={() => setIsSizeOptionsVisible(!isSizeOptionsVisible)}>
                      סנן לפי מידה
                    </button>
                    {isSizeOptionsVisible && (
                      <>
                        {['xs', 's', 'm', 'l', 'xl', 'xxl'].map((size) => (
                          <label key={size}>
                            <input
                              type="checkbox"
                              checked={selectedSizes.includes(size)}
                              onChange={() => handleSizeToggle(size)}
                            />
                            {size.toUpperCase()}
                          </label>
                        ))}
                      </>
                    )}
                    <br />
                    <button
                      onClick={() => {
                        filters();
                        setIsFilterMenuOpen(false);
                      }}
                    >
                      סנן
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <br />
        <br />
        <br />
        <div className="clothing-container">
  {images.map((image) => {
    const { item, color } = image;
    const isSold = image?.is_sold === "yes";
    return (
      <div className={`product-item ${isSold ? 'sold' : ''}`} key={item?.id}>
        {isSold ? (
          <div className="product-grid2">
            {image && (
              <div className="image-wrapper">
                <img src={image?.image} alt={item?.name} />
                <div className="sold-overlay">Sold</div>
              </div>
            )}
          </div>
        ) : (
          <Link
            to={`/itemInfo?color=${color}`}
            onClick={() => setSelectedItem(item)}
            className="product-grid2"
          >
            {item?.sale > 0 ? (
              <div className="sale-percent">{item.sale}% OFF</div>
            ) : null}
             {item?.new === "yes" ? (
              <div className="new">new</div>
            ) : null}
            {image && (
              <img src={image?.image} alt={item?.name} />
            )}
          </Link>
        )}
        <div className="price-container">
          <div className="item-name">{item?.name} ({color})</div>
          <div className="prices">
            {item?.sale > 0 ? (
              <>
                <div className="original-price">{item?.price} ₪</div>
                <div className="discounted-price">
                  {(item?.price * (1 - item.sale / 100)).toFixed(2)} ₪
                </div>
              </>
            ) : (
              <span className="discounted-price">{item?.price} ₪</span>
            )}
          </div>
        </div>
      </div>
    );
  })}
</div>
      </div>
    </div>
  );
}

export default Category;
