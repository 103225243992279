import React, { useEffect, useRef, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckFast } from '@fortawesome/free-solid-svg-icons';
import './Home.css'; 
import { AppContext } from '../App';
import axios from 'axios';
import { SERVER_URL } from './config';
import './ItemInfo.css'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import './Category.css';





const Home = () => {
  const videoRef = useRef(null);
  const {recommendedItems, recommendedImages, setSelectedItem} = useContext(AppContext);
  const navigate = useNavigate();

  const handleOnClick = async (item) => {
    try {
      const response = await axios.post(SERVER_URL + 'colors', { item: item.id });
      setSelectedItem(item);
      navigate(`/itemInfo?color=${response.data[0].color}`);
      window.location.reload();
    } catch (error) {
      console.error('Error find color', error);
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // 15 seconds
  };

  const settingsSmallerScreen = {
    ...settings,
    slidesToShow: 3, // Adjust this value for smaller screens
  };

  useEffect(() => {
    const video = videoRef.current;
    video.play().catch((error) => {
      console.error('Autoplay failed:', error);
    });

    return () => {
      if (video) {
        video.pause();
        video.currentTime = 0;
      }
    };
  }, []);

  const MemoizedMarqueeContent = React.memo(({ children }) => (
    <h1>{children}</h1>
  ));
  

  return (
    <div style={{ position: 'relative' }}>
      <div>
        <video
        className='video-background'
          ref={videoRef}
          playsInline 
          muted={true}
          loop={true}
          preload="auto"
          controls={false}
        >
  <source src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/WhatsApp+Video+2024-11-04+at+12.57.49.mp4" type="video/mp4" onError={(e) => console.error('Video Error:', e)} />
        </video>

      </div>
      <div className="text-overlay">
  <span className="emphasized">משלוח חינם</span> בקניה מעל ₪299                              <FontAwesomeIcon icon={faTruckFast} />
</div>

        <br />
        <div className="image-container">
  <div className="row">
    <div className="image-with-text">
      <Link to="/t-shirt">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/T-shirt"
          alt="Image 2"
          loading="lazy"  
        />
        <div className="overlay-text">חולצות</div>
      </Link>
    </div>
    <div className="image-with-text">
      <Link to="/class">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/class"
          alt="Image 1"
          loading="lazy"
        />
        <div className="overlay-text">מכופתרות</div>
      </Link>
    </div>
  </div>
  <div className="row">
    <div className="image-with-text">
      <Link to="/pants">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/pans"
          alt="Image 3"
          loading="lazy"
        />
        <div className="overlay-text">מכנסיים</div>
      </Link>
    </div>
    <div className="image-with-text">
      <Link to="/jaket">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/jakets"
          alt="Image 4"
          loading="lazy"
        />
        <div className="overlay-text">ג'קטים</div>
      </Link>
    </div>
  </div>
  <div className="row">
    <div className="image-with-text">
      <Link to="/exesories">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/exesories"
          alt="Image 3"
          loading="lazy"
        />
        <div className="overlay-text">אקססוריז</div>
      </Link>
    </div>
    <div className="image-with-text">
      <Link to="/hats">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/hats"
          alt="Image 4"
          loading="lazy"
        />
        <div className="overlay-text">כובעים</div>
      </Link>
    </div>
  </div>
  <div className="row">
    <div className="image-with-text">
      <Link to="/sale">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/sale"
          alt="Image 3"
          loading="lazy"
        />
        <div className="overlay-text">סייל</div>
      </Link>
    </div>
    <div className="image-with-text">
      <Link to="/shoes">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/shoes"
          alt="Image 4"
          loading="lazy"
        />
        <div className="overlay-text">נעליים</div>
      </Link>
    </div>
  </div>
</div>

<div className="recommended-items-container">
  {Array.isArray(recommendedItems) && recommendedItems.length > 0 ? (
    <Slider {...(window.innerWidth <= 768 ? settingsSmallerScreen : settings)} className="image_carousel-control">
      {recommendedItems.map((item) => {
      const matchingImage = recommendedImages.find((image) => image.item === item.id);

        return matchingImage ? (
          <div key={item.id} className="carousel-slide">
            <Link onClick={() => { handleOnClick(item) }}>
              <img
                src={matchingImage.image} 
                alt={`Recommended ${item.name}`}
                className="recommended-image"
              />
              <div className="price-container">
              <div className="plus">+</div>
              <div className="item-name">
  {item.name.split(' ')[0]}
</div>
              <div className="prices">
    {item?.sale > 0 ? (
      <>
       <div className="original-price">{item.price} ₪</div>
        <div className="discounted-price">{item.price - item.sale/100 * item.price} ₪</div>
      </>
    ) : (
      <span className="discounted-price">{item?.price} ₪</span>
    )}
  </div>
                    </div>
              </Link>
          </div>
        ) : null;
      })}
    </Slider>
  ) : (
    <p>No recommended items</p>
  )}
</div>  

<div className="last-image">
      <Link to="/class">
        <img
          src="https://renaissance-app.s3.eu-central-1.amazonaws.com/home/new"
          alt="last big image"
          loading="lazy"
        />
        <div className="overlay-text2">
  <p className="collection-text">New Collection</p>
  <p className="shop-now-text">Shop Now &rarr;</p>
</div>
      </Link>
    </div>
    </div>
  );
};

export default Home;
