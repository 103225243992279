import React, { useState } from 'react';
import axios from 'axios'; // Don't forget to import axios
import styles from './Contact.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp, faFacebook, faPinterest } from '@fortawesome/free-brands-svg-icons';
import { SERVER_URL } from './config';

const ContactComponent = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');

  const redirectToInstagram = () => {
    window.open('http://www.instagram.com/renaissance.il', '_blank');
  };

  const handleWhatsappClick = () => {
    const whatsappLink = `https://wa.me/${+972535210989}`;
    window.open(whatsappLink, '_blank');
  };

  const sendContact = async () => {
    try {
      const response = await axios.post(SERVER_URL + 'contact', { email, name, message });
    } catch (error) {
      console.error('Error sending message', error);
    }
  };

  return (
    <div className={styles.bodycontact}>
      <section id="contact" className={styles.contact}>
        <h1 className={styles['section-header']}>Contact</h1>
        <div className={styles['contact-wrapper']}>
          {/* Left contact page */}
          <form
            id="contact-form"
            className={`${styles['form-horizontal']} ${styles['form-wrapper']}`}
            role="form"
          >
            <div className={styles['form-group']}>
              <div className={styles['col-sm-12']}>
                <input
                  type="text"
                  className={styles['form-control']}
                  id="name"
                  placeholder="שם פרטי+משפחה"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            </div>

            <div className={styles['form-group']}>
              <div className={styles['col-sm-12']}>
                <input
                  type="email"
                  className={styles['form-control']}
                  id="email"
                  placeholder="אימייל"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            </div>

            <textarea
              className={styles['form-control']}
              rows="10"
              placeholder="תוכן הפניה"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>

            <button
              onClick={sendContact}
              className={`${styles['btn']} ${styles['btn-primary']} ${styles['send-button']}`}
              id="submit"
              type="button" // Change to "button" to prevent form submission
              value="SEND"
              block="true"
            >
              <div className={styles['alt-send-button']}>
                <i className={`${styles['fa']} ${styles['fa-paper-plane']}`}></i>
                <span className={styles['send-text']}>שליחת ההודעה</span>
              </div>
            </button>
          </form>

          {/* Right contact page */}
          <div className={styles['direct-contact-container']}>
            <ul className={styles['contact-list']}>
              <li className={styles['list-item']}>
                <i className={`${styles['fa']} ${styles['fa-map-marker']} ${styles['fa-2x']}`}>
                  <span className={`${styles['contact-text']} ${styles['place']}`}>Israel, Givat Zeev</span>
                </i>
              </li>

              <li className={styles['list-item']}>
                <i className={`${styles['fa']} ${styles['fa-phone']} ${styles['fa-2x']}`}>
                  <span className={`${styles['contact-text']} ${styles['phone']}`}>
                    <a href="tel:1-212-555-5555" title="Give me a call">(+972) 53-521-0989</a>
                  </span>
                </i>
              </li>

              <li className={styles['list-item']}>
                <i className={`${styles['fa']} ${styles['fa-envelope']} ${styles['fa-2x']}`}>
                  <span className={`${styles['contact-text']} ${styles['gmail']}`}>
                    <a href="mailto:#" title="Send me an email">renaissance.il.one@gmail.com</a>
                  </span>
                </i>
              </li>
            </ul>

            <hr />

            <ul className={styles['social-media-list']}>
                <button onClick={redirectToInstagram} className={styles['contact-icon']}>
                  <FontAwesomeIcon icon={faInstagram} />
                </button>
                <button onClick={handleWhatsappClick} className={styles['contact-icon']}>
                  <FontAwesomeIcon icon={faWhatsapp}/>
                </button>
                <button target="_blank" className={styles['contact-icon']}>
                  <FontAwesomeIcon icon={faFacebook} />
                </button>
                <button target="_blank" className={styles['contact-icon']}>
                  <FontAwesomeIcon icon={faPinterest} />
                </button>
            </ul>

            <hr />
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactComponent;
