import React, { useContext, useEffect, useState } from 'react';
import CouponPopup from './CouponPopup'; // Adjust the path to your CouponPopup component
import { AppContext } from '../App';
import styles from './Cart.module.css';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from './config';


function Cart() {
  const { addToCart, setAddToCart, setTotalPrice, isCartOpen, user, isLogged, setSelectedItem, setIsCartOpen } = useContext(AppContext);
  const [isOrders, setIsOrders] = useState('')
  const [showCouponPopup, setShowCouponPopup] = useState(false);
  const navigate = useNavigate()


  const groupCartItems = (cart) => {
    return Object.values(
      cart.reduce((acc, item) => {
        const key = `${item.name}_${item.selectedSize.size}_${item.color}`;
        if (!acc[key]) {
          acc[key] = { ...item, quantity: item.quantity || 1 };
        } else {
          acc[key].quantity += item.quantity || 1;
        }
        return acc;
      }, {})
    );
  };

  const orders = async() => {
    try {
      if (user && user.id) {
        const response = await axios.post(SERVER_URL + 'is_orders', { user: user.id });
        setIsOrders(response.data);
      } else {
      }
    } catch(error) {
      console.error('Error find orders', error);
    }
  };


  const InStock = async () => {
    try {
      const response = await axios.post(SERVER_URL + 'is_in_stock', { items: cartItems });
      if (response.data === 'good') {
        setShowCouponPopup(true); // Show the coupon popup if items are in stock
      } else {
        alert('ישנם פריטים שאזלו במלאי, נא לבחור מידה שנמצאת במלאי');
      }
    } catch (error) {
      console.error('Error checking stock', error);
    }
  };

  const handleApplyCoupon = async (coupon) => {
    try {
      const response = await axios.post(SERVER_URL + 'apply_coupon', { coupon });
      if (response.data !== "no") {
        const discountPercentage = response.data; // Assuming response.data contains the discount percentage
        const discount = totalPrice * (discountPercentage / 100); // Calculate the discount amount
        setTotalPrice(totalPrice - discount);
        alert(`${discountPercentage}% קופון התווסף בהצלחה קיבלת הנחה של`);
      } else {
        alert('קוד קופון לא קיים');
      }
    } catch (error) {
      console.error('Error applying coupon', error);
    } finally {
      setShowCouponPopup(false);
      setIsCartOpen(false);
      navigate('/pay');
    }
  };

  const cartItems = groupCartItems(addToCart);

  const totalPrice = cartItems.reduce((acc, item) => {
    orders()
    const itemPrice = item.sale ? item.price - (item.price * item.sale) / 100 : item.price;
    const finalPrice = isLogged && isOrders === 'fals' ? itemPrice - (itemPrice * user.sale) / 100 : itemPrice;
    return acc + finalPrice * item.quantity;
  }, 0);

  const moneySaved = cartItems.reduce((acc, item) => {
    const originalPrice = item.price * item.quantity;
    const discountedPrice = item.sale
      ? item.price - (item.price * item.sale) / 100
      : item.price;
    const finalPrice = isLogged && isOrders === 'fals' ? discountedPrice - (discountedPrice * user.sale) / 100 : discountedPrice;
    const savingsForItem = Math.floor(originalPrice - finalPrice * item.quantity);

  return acc + savingsForItem;
}, 0);


const handleIncreaseQuantity = (item) => {
  setAddToCart((prevCart) => {
    const updatedCart = prevCart.map((cartItem) => {
      if (cartItem.id === item.id && cartItem.selectedSize === item.selectedSize) {
        const maxQuantity = item.selectedSize.count; // Limit to available stock
        return {
          ...cartItem,
          quantity: cartItem.quantity < maxQuantity ? cartItem.quantity + 1 : cartItem.quantity,
        };
      }
      return cartItem;
    });
    return groupCartItems(updatedCart);
  });
};


  const handleDecreaseQuantity = (item) => {
    setAddToCart((prevCart) => {
      const updatedCart = prevCart.map((cartItem) =>
        cartItem.id === item.id && cartItem.selectedSize === item.selectedSize
          ? { ...cartItem, quantity: cartItem.quantity > 1 ? cartItem.quantity - 1 : 1 }
          : cartItem
      );
      return groupCartItems(updatedCart);
    });
  };

    const handleRemoveItem = (item) => {
      const confirmRemove = window.confirm('האם אתה בטוח שברצונך להסיר פריט זה מסל הקניות?');
    
      if (confirmRemove) {
        setAddToCart((prevCart) => {
          const updatedCart = prevCart.filter(
            (cartItem) =>
              !(cartItem.id === item.id && cartItem.selectedSize === item.selectedSize && cartItem.color === item.color)
          );
          return groupCartItems(updatedCart);
        });
      }
    };  
    
    useEffect(() => {
      setTotalPrice(totalPrice); // You can perform additional logic here if needed
    }, [totalPrice]);


  return (
    <div className={`${styles['cart-container']} ${isCartOpen ? styles['cart-container-open'] : styles['cart-container-closed']}`}>
      {isCartOpen && (
        <div className={styles['cart-content']}>
        {cartItems.length === 0 ? (
            <p>סל הקניות שלך ריק</p>
          ) : (
            <>
              <ul>
              {cartItems.map((item) => (
                <Link
                key={`${item.id}_${item.selectedSize}_${item.color}`}  // Add key prop here
                to={`/itemInfo?color=${item.color}`}
                onClick={() => {
                    setSelectedItem(item); 
                }} >
              <li className={styles['li']}>
              <button onClick={() => handleRemoveItem(item)} style={{ padding: '0', border: 'none', background: 'none', color: 'black'}}>x</button>
                <img src={item.images[0]?.image} alt={item.name} />
                <div>
                  <h3>{item.name}</h3>
                  <h3>{item.color}</h3>
                  <p>{item.info}</p>
                  <p>{item.selectedSize.size}</p>
                  {item.sale && (
                    <p>
                      <span className={styles['original-price']}>₪{item.price}</span>
                      <br />
                      {' :אחרי הנחה'}
                      <br />
                      ₪{item.price - (item.price * item.sale) / 100}
                    </p>
                  )}
                  {!item.sale && <p>מחיר: ₪{item.price}</p>}
                  <div className={styles['quantity-buttons']}>
                    <button onClick={() => handleDecreaseQuantity(item)}>-</button>
                    <span>{item.quantity}</span>
                    <button onClick={() => handleIncreaseQuantity(item)}>+</button>
                  </div>
                  </div>
                </li>
                </Link>
                ))}
              </ul>
              <div className={styles['total-price']}>
              {isOrders === 'fals' && isLogged ? (
                 <p>מגיע לך בקניה זו 10% הנחה</p>
                ): (
                  <p></p>
                )}
                <p>מחיר סופי: ₪{totalPrice}</p>
                {moneySaved > 0 && <p>בקניה זו חסכת ₪{moneySaved}</p>}
                {/* {setTotalPrice(totalPrice)} */}
                <button className={`${styles['link-button']}`} onClick={InStock}>
                        מעבר לתשלום
                   </button>                
                 </div>
              {showCouponPopup && (
                <CouponPopup
                  onClose={() => {
                    setShowCouponPopup(false);
                    setIsCartOpen(false);
                    navigate('/pay');
                  }}
                  onApplyCoupon={handleApplyCoupon}
                />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;