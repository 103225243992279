import React, { useEffect } from 'react';
import axios from 'axios';
import { SERVER_URL } from './config'


const Logout = () => {
  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found.');
      } else {
        await axios.post(SERVER_URL + 'logout', { token: token });
        localStorage.removeItem('authToken');
        window.location.href = '/'; 
      }
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  useEffect(() => {
    handleLogout(); 
  }, []);

  return (
    <div>
      Logging out...
    </div>
  );
};

export default Logout;
