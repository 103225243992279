import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import styles from './Cart.module.css';
import { Link } from 'react-router-dom';


function WishList() {
  const { addToWishList, setAddToWishList, isWishListOpen, setSelectedItem } = useContext(AppContext);

  
  const handleRemoveItem = (item) => {
    setAddToWishList((prevList) => {
      const updatedList = prevList.filter(
        (listItem) =>
          !(listItem.id === item.id && listItem.color === item.color)
      );
      return updatedList;
    });
  };    
  


  return (
    <div className={`${styles['cart-container']} ${isWishListOpen ? styles['cart-container-open'] : styles['cart-container-closed']}`}>
      {isWishListOpen && (
        <div className={styles['cart-content']}>
        {addToWishList.length === 0 ? (
            <p>הרשימה שלך ריקה</p>
          ) : (
            <>
              <ul>

              {[...new Set(addToWishList.map(item => `${item.id}_${item.color}`))].map((uniqueKey) => {
                  const item = addToWishList.find(item => `${item.id}_${item.color}` === uniqueKey);

                  return (
                    <Link
                      key={uniqueKey}
                      to={`/itemInfo?color=${item.color}`}
                      onClick={() => {
                        setSelectedItem(item);
                      }}
                    >
                      <li className={styles['li']}>
                        <button
                          onClick={() => handleRemoveItem(item)}
                          style={{ padding: '0', border: 'none', background: 'none', color: 'black' }}
                        >
                          x
                        </button>
                        <img src={item.images[0]?.image} alt={item.name} />
                        <div>
                          <h3>{item.name}</h3>
                          <p>{item.info}</p>
                          {item.sale && (
                            <p>
                              <span className={styles['original-price']}>₪{item.price}</span>
                              <br />
                              {' :אחרי הנחה'}
                              <br />
                              ₪{item.price - (item.price * item.sale) / 100}
                            </p>
                          )}
                          {!item.sale && <p>מחיר: ₪{item.price}</p>}
                        </div>
                      </li>
                    </Link>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default WishList;