import { SERVER_URL } from './config'
import React, { useContext, useState } from 'react';
import axios from 'axios';
import './LogIn.css';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { getLoginToken, validateToken } from './conectionClient';
import { AppContext } from '../App';
import { Modal } from 'react-bootstrap';


const ErrorModal = ({ open, onClose }) => {
    return (
      <Modal show={open} onHide={onClose} dialogClassName="popup-content" centered>
        <Modal.Body>
          <a className="message">שם משתמש או סיסמא שגויים</a>
          <br/>
          <a className="message"> אנא נסה שנית</a>

        </Modal.Body>
      </Modal>
    );
  };


const Login = () => {
  
  const {setIsLogged, setUser} = useContext(AppContext)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const navigate = useNavigate();


  const handleLogin = async (event) => {
    event.preventDefault();
    const token = await getLoginToken(username, password);
      if (token) {
            const data = await validateToken();
            setUser(data);
            setIsLogged(true);
            localStorage.setItem('authToken', token);
            navigate('/');
          } else {
            setShowErrorModal(true)
          }
        };
  
  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <div className="login-container">
      <div className="login-frame">
        <h2>כניסה</h2>
        <form>
          <div className="user-box">
            <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
            <label>שם משתמש</label>
          </div>
          <div className="user-box">
            <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <label>סיסמא</label>
          </div>
          <button type="submit" onClick={handleLogin}>כניסה</button>
      <ErrorModal open={showErrorModal} onClose={handleCloseErrorModal} />
      </form>
      <p className="signup-link">
        אין לך חשבון? <Link to="/signup">הירשם כאן</Link>
      </p>
      </div>
    </div>
  );
};

export default Login;
