import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import axios from 'axios';
import { SERVER_URL } from './config';
import './ItemInfo.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';


const ItemInfo = () => {
  const { selectedItem, setSelectedItem, setAddToCart, recommendedItems, isCartOpen, setIsCartOpen,
           setAddToWishList, isWishListOpen, setIsWishListOpen} = useContext(AppContext);
  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [images, setImages] = useState([])
  const [sizes, setSizes] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const location = useLocation();
  const colorParam = new URLSearchParams(location.search).get('color');
  const [availableColors, setAvailableColors] = useState([]); 
  const [showQuestionsAnswers, setShowQuestionsAnswers] = useState(false);
  const navigate = useNavigate();
  const [touchStartX, setTouchStartX] = useState(0);
  
  
  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    if (touchStartX - touchEndX > 50) {
      // Swipe left, go to the next image
      handleNextImage();
    } else if (touchStartX - touchEndX < -50) {
      // Swipe right, go to the previous image
      handlePrevImage();
    }
  };


  const toggleQuestionsAnswers = () => {
    setShowQuestionsAnswers(!showQuestionsAnswers);
  };

  const handleWhatsAppLink = () => {
    window.open('https://wa.me/972535210989', '_blank');
  };

  const handleSizeChange = (size) => {
    setSelectedSize(size);
  };

  const handleColorChange =  (color) => {
    setSelectedColor(color);
    getImage(color);
    getSizes(color);
  };

  const getImage = async(color) => {
    try {
        const response = await axios.post(SERVER_URL+'get_images', {item: selectedItem.id, color: color})
        console.log(response)
        setImages(response.data)
      } catch(error) {
        console.error('Error find pictures', error)
      }
  }

  const getColors = async() => {
    try {
        const response = await axios.post(SERVER_URL+'colors', {item: selectedItem.id})
        const extractedColors = response.data.map(item => item.color);
        setAvailableColors(prevColors => {
          return [...new Set([...prevColors, ...extractedColors])];
        });    
      } catch(error) {
        console.error('Error find colors', error)
      }
  }


  const handleOnClick = async (item) => {
    try {
      setSelectedItem(item.item);
      setSelectedColor(item.color);
      navigate(`/itemInfo?color=${item.color}`);
      window.location.reload();
    } catch (error) {
      console.error('Error find color', error);
    }
  };
  

  

  const getSizes = async(color) => {
    try {
        const response = await axios.post(SERVER_URL+'get_sizes', {item: selectedItem.id, color: color})
        setSizes(response.data)
      } catch(error) {
        console.error('Error find sizes', error)
      }
  }

  const handleAddToCart = () => {
    if (selectedSize) {
      setAddToCart((prevCart) => [
        ...prevCart,
        {
          ...selectedItem,
          selectedSize: selectedSize.size, // If selectedSize is an object with more properties
          images,
          color: selectedColor ? `${selectedColor}` : `${colorParam}`, // Use selectedColor or colorParam
        },
      ]);
        setIsCartOpen(!isCartOpen);
      } else {
      alert('נא לבחור מידה לפני הוספה לסל');
    }
  };

  const handleAddToWishList = () => {
    setAddToWishList((prevList) => [
      ...prevList,
      {
        ...selectedItem,
        selectedSize: selectedSize.size, // Only pass the size value
        images,
        color: selectedColor ? `${selectedColor}` : `${colorParam}`, // Use selectedColor or colorParam
      },
    ]);
    setIsWishListOpen(!isWishListOpen);
  };

  useEffect(() => {
    getColors();
    getImage(colorParam);
    getSizes(colorParam);
  }, []);


   const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  const calculateDiscountedPrice = () => {
    if (selectedItem.sale > 0) {
      const discountedPrice = selectedItem.price * ((100 - selectedItem.sale) / 100);
      return discountedPrice.toFixed(2);
    }
    return null;
  };

  const englishColorMapping = {
    'ורוד': 'pink',
    'ירוק': 'green',
    'שחור': 'black',
    'לבן': 'white',
    'אפור': 'gray',
    'טורקיז': 'turquoise',
    'סגול': 'purple',
    'כחול': 'blue',
    'חום': 'brown',
    'אדום': 'red',
    'אפור כהה': 'dark gray',
    "בז'": 'beige',
    'תכלת': 'azure',
    'ירוק זית': 'olive green',
    'צהוב': 'yellow',
    'כתום': 'orange',
    'כללי': 'general',
    'שמנת': 'cream', 
    "ג'ינס": 'blue', 
  };
  

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000, // 15 seconds
  };

  const settingsSmallerScreen = {
    ...settings,
    slidesToShow: 3, // Adjust this value for smaller screens
  };


  const regularSizes = ['S', 'M', 'L', 'XL', 'XXL'];
  const pantsSizes = ['26', '28', '29', '30', '31', '32', '33', '34', '35', '36']; // Example pants sizes
  const shoesSizes = ['40', '41', '42', '43', '44', '45', '46']; // Example shoes sizes
  
  const isPants = selectedItem.is_pants === 'yes'; // Assuming you have a way to determine if the item is pants
  const isShoes = selectedItem.is_shoes === 'yes';
  
  const sizeOptions = isPants ? pantsSizes : (isShoes ? shoesSizes : regularSizes);
  

return (
  <div>
    <div className="item-info-container">
      <div className="item-details">
        <h2>{selectedItem.name} {selectedColor ? `(${selectedColor})` : `(${colorParam})`}</h2>
        {selectedItem.sale > 0 && (
          <>
            <p className="original-price">{selectedItem.price}₪</p>
            <p className="discounted-price">{`sale ${selectedItem.sale}% `}</p>
            <p>מחיר אחרי הנחה</p>
            <p className="">{calculateDiscountedPrice()}₪</p>
          </>
        )}
        {selectedItem.sale === 0 && <p>{selectedItem.price}₪</p>}
        <br />
        <p>{selectedItem.info}</p>

        <div className="color-options">
          <p className="pick">בחר צבע</p>
          <div className="colors">
            {availableColors.map((color) => (
              <div
                key={color}
                className={`color ${color === selectedColor ? 'selected' : ''}`}
                style={{ backgroundColor: englishColorMapping[color] }}
                onClick={() => handleColorChange(color)}
              ></div>
            ))}
          </div>
        </div>

        <div className="size-options">
          <p className="pick">בחר מידה</p>
          <div className="sizes">
            {sizeOptions.map((size) => {
              const availableSize = sizes.find((s) => s.size === size);
              const isAvailable = availableSize && availableSize.count > 0;

              return (
                <div
                  key={size}
                  className={`size ${selectedSize === availableSize ? 'selected' : ''} ${isAvailable ? '' : 'unavailable'}`}
                  onClick={() => (isAvailable ? handleSizeChange(availableSize) : null)}
                >
                  {size}
                  {!isAvailable && <span className="unavailable-icon">X</span>}
                </div>
              );
            })}
          </div>
          <button onClick={handleAddToCart} className='add'>הוסף לסל</button>
          <button onClick={handleAddToWishList} className='add'>add to wish list</button>
        </div>

        <div className={`ask ${showQuestionsAnswers ? 'active' : ''}`} onClick={toggleQuestionsAnswers}>
          <button>שאלות תשובות </button>
          {showQuestionsAnswers && (
            <div className="ask-content">
              <p>~כמה זמן משלוח ואיך הוא מגיע אליי~</p>
              <br />
              <p>1-5 ימי עסקים</p>
              <p>המשלוח מגיע עם שליח עד הבית</p>
              <br /><br />
              <p>~מה עלות משלוח~</p>
              <br />
              <p>39.99₪</p>
              <p>משלוח חינם בקנייה מעל 299₪</p>
              <br /><br />
              <p>~איפה אתם נמצאים~</p>
              <br />
              <p>הסטודיו ממוקם על יד ירושלים-מודיעין בגבעת זאב</p>
              <br /><br />
              <p>~מתי אפשר להגיע~</p>
              <br />
              <p>הגעה בתיאום מראש בלבד</p>
              <p>אפשר לשלוח ווצאפ לתיאום</p>
              <br />
              <p>~יש לי שאלה אחרת~</p>
              <br />
              <p>מעולה! תוכל ליצור איתנו קשר בווצאפ ונציג שלנו יחזור אליך בהקדם האפשרי</p>
              <br />
            </div>
          )}
        </div>
        <br/>
        <br/>
        <button onClick={handleWhatsAppLink} className="whatsapp-icon-button">
          <span className="whatsapp-icon">
            <FontAwesomeIcon icon={faWhatsapp} />
          </span>
          <span className="whatsapp-text">
            שאל אותנו על<br />מוצר זה בווצאפ
          </span>
        </button>
      </div>

      <div className="image-gallery">
  <button className="carousel-control" onClick={handlePrevImage}>
    &lt;
  </button>
  <div
    className="gallery-container"
    onWheel={(e) => {
      if (e.deltaX > 10) {
        handleNextImage();
      } else if (e.deltaX < -10) {
        handlePrevImage();
      }
    }}
    onTouchStart={handleTouchStart}
    onTouchEnd={handleTouchEnd}
    style={{ overflowX: 'hidden' }} // Prevents a scrollbar
  >
    <img
      key={images[currentImageIndex]?.id}
      src={images[currentImageIndex]?.image}
      alt={`${selectedItem.id}-${images[currentImageIndex]?.id}`}
      className="gallery-image"
    />
  </div>
  <button className="carousel-control" onClick={handleNextImage}>
    &gt;
  </button>
      </div>
    </div>

    <div className="recommended-items-container">
  <p>פריטים שאולי תאהב</p>
  {Array.isArray(recommendedItems) && recommendedItems.length > 0 ? (
    <Slider
      {...(window.innerWidth <= 768 ? settingsSmallerScreen : settings)}
      className="image_carousel-control"
    >
      {recommendedItems.map((item) => (
        <div key={item.item.id} className="carousel-slide">
          <Link onClick={() => handleOnClick(item)}>
            <img
              src={item.image}
              alt={`Recommended ${item.item.name}`}
              className="recommended-image"
            />
          </Link>
        </div>
      ))}
    </Slider>
  ) : (
    <p>No recommended items</p>
  )}
</div>

  </div>
);
};

export default ItemInfo;
