import React, { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import Home from './Home'
import Category from './Category'
import About from './About'
import ItemInfo from './ItemInfo'
import Pay from './Pay'
import FinishPay from './FinishPay'
import Signup from './Signup'
import Login from './Login'
import Logout from './Logout'
import WishList from './WishList'
import Terms from './Terms'
import PopupModal from './PopupModal'

function SiteRoutes() {
    const location = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

  return (
    <>
    <Routes>
    <Route path='/' element={<Home/>}/>
    <Route path="/about" element={<About/>} />
    <Route path='/t-shirt' element={<Category categoryProp='חולצות'/>}/>
    <Route path='/pants' element={<Category categoryProp='מכנסיים'/>}/>
    <Route path='/jaket' element={<Category categoryProp='גקטים'/>}/>
    <Route path="/itemInfo" element={<ItemInfo/>} />
    <Route path='/shoes' element={<Category categoryProp='הנעלה'/>}/>
    <Route path='/sale' element={<Category categoryProp='סייל'/>}/>
    <Route path='/exesories' element={<Category categoryProp='אקססוריז'/>}/>
    <Route path='/parties' element={<Category categoryProp='מסיבות'/>}/>
    <Route path='/hats' element={<Category categoryProp='כובעים'/>}/>
    <Route path='/new' element={<Category categoryProp='new'/>}/>
    <Route path='/class' element={<Category categoryProp='מכופתרות'/>}/>
    <Route path='/pay' element={<Pay/>}/>
    <Route path='/finishPay' element={<FinishPay/>}/>
    <Route path='/signup' element={<Signup/>}/>
    <Route path='/login' element={<Login/>}/>
    <Route path='/logout' element={<Logout/>}/>
    <Route path='wishlist' element={<WishList/>}/>
    <Route path='/term' element={<Terms/>}/>
    <Route path='/popup' element={<PopupModal/>}/>
    </Routes>
    </>
  )
}

export default SiteRoutes;