import React, { useContext, useState } from 'react';
import './Pay.css'; // Import your CSS file
import axios from 'axios';
import { SERVER_URL } from './config';
import { Link, useNavigate } from 'react-router-dom';
import { AppContext } from '../App';

function Pay() {
    const [formData, setFormData] = useState({
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      city: '',
      street: '',
      houseNumber: '',
      floor: '',
      remarks: '',
      shipping: '',
      apartmentNumber: '',
      agreementChecked: false,
    });
  
    const [allInputsFilled, setAllInputsFilled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {totalPrice, setTotalPrice, add, setAdd} = useContext(AppContext)
    const navigate = useNavigate();
  
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      const inputValue = type === 'checkbox' ? checked : value;
      
      if (name === 'shipping') {
        if (value.includes('משלוח מהיר') && totalPrice <= 29){
          setAdd(30)
        }
       else{
         setAdd(0)
       }}

      setFormData((prevData) => ({ ...prevData, [name]: inputValue }));
    };
  
    const isFormFilled = () => {
      return (
        formData.firstName &&
        formData.lastName &&
        formData.phone &&
        formData.email &&
        formData.city &&
        formData.street &&
        formData.houseNumber &&
        formData.shipping &&
        formData.agreementChecked
      );
    };
  
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
      
        if (isFormFilled()) {
            navigate('/finishPay', { state: { formData } });
        }
      };
      
  
      const checkInputsFilled = (e) => {
        e.preventDefault(); // Prevent the default form submission
      
        const requiredInputs = [
          'firstName',
          'lastName',
          'phone',
          'email',
          'city',
          'street',
          'houseNumber',
          'shipping',
        ];
      
        const areAllInputsFilled = requiredInputs.every((input) => formData[input].trim() !== '');
      
        setAllInputsFilled(areAllInputsFilled);
      
        if (!areAllInputsFilled) {
          setErrorMessage('נא למלא את כל הפרטים הדרושים ולאשר את תנאי התקנון');
        } else if (!formData.agreementChecked) {
          setErrorMessage('יש לאשר את תנאי התקנון');
        } else {
          setErrorMessage('');
          handleSubmit(e); // Submit the form if everything is filled
        }
      };
      
      
  
    return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <label>
          בחירת משלוח
        <select name="shipping" value={formData.shipping} onChange={handleChange}>
        <option></option>
         <option>משלוח מהיר 1-7 ימי עסקים (30 שקלים או חינם בקנייה מעל 299)</option>
         <option>איסוף עצמי מסניף גבעת זאב- בתיאום מראש בלבד</option>
        </select>
        </label>

        <label>
          שם פרטי
          <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
        </label>

        <label>
          שם משפחה
          <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
        </label>

        <label>
          טלפון
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange} />
        </label>

        <label>
          אימייל
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </label>

        <label>
          יישוב
          <input type="text" name="city" value={formData.city} onChange={handleChange} />
        </label>

        <label>
          רחוב
          <input type="text" name="street" value={formData.street} onChange={handleChange} />
        </label>

        <label>
          מספר בית/בניין
          <input type="text" name="houseNumber" value={formData.houseNumber} onChange={handleChange} />
        </label>

        <label>
          קומה
          <input type="text" name="floor" value={formData.floor} onChange={handleChange} />
        </label>

        <label>
          מספר דירה
          <input type="text" name="apartmentNumber" value={formData.apartmentNumber} onChange={handleChange} />
        </label>

        <label>
          הערות
          <input type="text" name="remarks" value={formData.remarks} onChange={handleChange} />
        </label>

        <label style={{display:'flex', marginLeft:'20%'}}>
        <p>  קראתי ואני מאשר את <Link to="/term">תנאי התקנון</Link></p>
        <input
            type="checkbox"
            name="agreementChecked"
            checked={formData.agreementChecked}
            onChange={handleChange}
          />  
        </label>
        
       <button onClick={(e) => { e.preventDefault(); checkInputsFilled(e); }}>
          המשך לתשלום
       </button>

        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </form>
    </div>
  );
}

export default Pay;
