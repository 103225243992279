import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SERVER_URL } from './config';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import 'reactjs-popup/dist/index.css';



const Signup = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false); 
  const [showNotAllFull, setShowNotAllFull] = useState(false); 
  const [phone, setPhone] = useState('');
  const [date, setDate] = useState('')
  const [agreeToSales, setAgreeToSales] = useState(false);


  const ErrorModal = ({ open, onClose }) => {
    return (
      <Modal show={open} onHide={onClose} dialogClassName="popup-content" centered>
        <Modal.Body>
          <a className="message">אימייל או שם משתמש כבר בשימוש</a>
          <br/>
          <a className="message"> אנא נסה שנית</a>

        </Modal.Body>
      </Modal>
    );
  };
  
  const SuccessModal = ({ open, onClose }) => {
    return (
      <Modal show={open} onHide={onClose} dialogClassName='popup-content' centered>
      <Modal.Body>
          <div className="message">נרשמת בהצלחה</div>
          <Link to="/" className="link">
            לכניסה לחץ כאן
          </Link>
        </Modal.Body>
      </Modal>
    );
  };
  
    
  const NotAllFull = ({ open, onClose }) => {
    return (
      <Modal show={open} onHide={onClose} dialogClassName='popup-content' centered>
      <Modal.Body>
          <div className="message">נא למלא את כל השדות</div>
        </Modal.Body>
      </Modal>
    );
  };
  


  const handleSignup = async (event) => {
    event.preventDefault();

    if (!username || !password || !email || !phone || !date) {
      setShowNotAllFull(true);
      return;
    }

    try {
      const response = await axios.post(SERVER_URL + 'signup', { username, password, email, phone, agreeToSales, date});
      if (response.data.message) {
        setShowErrorModal(false)
        setShowSuccessModal(true);
        localStorage.setItem("token", response.data.token);
      } else {
        setShowErrorModal(true)
        setShowSuccessModal(false);
        console.error('Signup unsuccessful:', response.data.message);
      }
    } catch (error) {
      setShowErrorModal(true)
      setShowSuccessModal(false);
      console.error(error);
    }
  };


  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
  };
  
  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const handleCloseNotAllFull = () => {
    setShowNotAllFull(false);
  };

  return (
     <div className="login-container">
    <div className="login-frame">
      <h2>הרשמה</h2>
      <form>
        <div >
          <label>שם משתמש</label>
          <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div >
          <label>סיסמא</label>
          <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        </div>
        <div >
          <label>אימייל</label>
          <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div >
          <label>טלפון</label>
          <input type="tel" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
        </div>
        <div >
          <label>יום הולדת</label>
          <input type="date" name="date" value={date} onChange={(e) => setDate(e.target.value)} />
        </div>
       <div className="checkbox-container">
  <label className="checkbox-label">
    מסכים לקבל הודעות על מבצעים
  </label>
  <input
      className="checkbox-input"
    type="checkbox"
    name="agreeToSales"
    checked={agreeToSales}
    onChange={() => setAgreeToSales(!agreeToSales)}
  />
</div>

        <br/>
        <button type="button" onClick={handleSignup}>הירשם</button>
        <ErrorModal open={showErrorModal} onClose={handleCloseErrorModal} />
          <SuccessModal open={showSuccessModal} onClose={handleCloseSuccessModal} />
          <NotAllFull open={showNotAllFull} onClose={handleCloseNotAllFull} />
            <p className="signup-link">כבר רשום?  <Link to="/login">לכניסה</Link></p>
      </form>
    </div>
    </div>

  );
};

export default Signup;