import React from 'react';

function Terms() {
  return (
    <div style={{marginTop:'70px', textAlign:'right'}}>
      <p>
        תקנון
        <br />
        <br />
        התקנון נכתב בלשון זכר מטעמי נוחות וחל על שני המינים. אנא קרא בקפידה את תנאי השימוש.
        <br />
        <br />
        האתר מכיל חנות אינטרנטית מקוונת לרכישת מוצרים, ותנאי השימוש חלים בעת שימוש או רכישת מוצרים באתר.
        <br />
        <br />
        האתר, וכל שירות המסופק באמצעותו, לרבות עיצוב, תוכן, סימני מסחר, תוכן, מערכות ואלגוריתמים, מוגנים תחת דיני זכויות יוצרים ובאמצעות זכויות קניין רוחני.
        <br />
        <br />
        אין להעתיק לשכפל, להפיץ, לפרסם או להשתמש בכל דרך אחרת את התכנים המופיעים באתר.
        <br />
        <br />
        שימוש ורכישת מוצרים באתר מהווה את הסכמתך לתנאי השימוש, בה אתה מתחייב כי לא תהא לך או למי מטעמך כל טענה או דרישה נגד האתר.
        <br />
        <br />
        תנאי השימוש הכתובים בתקנון זה, הם הכללים היחידים אשר יחייבו את בעלי העסק ומפעילי האתר, כלפי המשתמשים בשירותי האתר.
        <br />
        <br />
        שים לב כי תנאי השימוש עלולים להשתנות מעת לעת ע"פ שיקול הדעת הבלעדי של מפעילי האתר וללא צורך בהודעה.
        <br />
        הערה: השינויים יחולו על מוצרים/שירותים שנרכשו לאחר השינוי בלבד.
        <br />
        <br />
        אם אינך מסכים לתנאי התקנון, הנך מתבקש לא להירשם לאתר או לבצע הזמנות.
        <br />
        <br />
        מקום השיפוט הייחודי והבלעדי בגין כל עניין הנובע מהשימוש באתר, הוא אך ורק בבתי המשפט המוסמכים בתל אביב ועל השימוש באתר יחולו אך ורק דיני מדינת ישראל.
        <br />
        <br />
        שימוש באתר
        <br />
        על המשתמש באתר להיות בגיל 18 ומעלה, ובתנאי כי הינו בעל כשרות משפטית.
        <br />
        <br />
        על המשתמש להתגורר בישראל ולהחזיק בת.ז ישראלית, כמו כן עליו להחזיק כרטיס אשראי בתוקף שהונפק בישראל.
        <br />
        <br />
        על אחריות המשתמש בלבד לשמור על סודיות הסיסמא ושם המשתמש לאתר.
        <br />
        <br />
        אסור למשתמש לפתוח חשבון לכל גורם צד שלישי ללא הסכמתו המפורשת.
        <br />
        <br />
        המשתמש לא ישתמש בפרטים וססמאות של משתמשים אחרים בניגוד לחוק.
        <br />
        <br />
        המשתמש מסיר אחריות מהאתר, בכל נושא של תקלה טכנית/הפרעה/נפילת האתר, לרבות תקלות העלולות להיגרם ע"י צד שלישי המספק שירותי אחסון, תוכנה, קווי תקשורת ו/או כל שירות נוסף.
        <br />
        <br />
        המשתמש מסיר אחריות מהחברה, בכל נושא של התקפות וניסיונות פריצה מצד גורמים שונים אל האתר, רשתות התקשורת, מחשבים ושרתים.
        <br />
        <br />
        החברה נוקטת במספר אמצעי אבטחה על מנת לשמור על פרטיות המידע שיוזן על ידי המשתמשים באתר, אולם חשוב לדעת כי לא ניתן להבטיח זאת לחלוטין וייתכנו פריצות אבטחה וחדירות למידע.
        <br />
        <br />
        החברה אינה מתחייבת שהשירותים והמידע באתר או בשרתי צד שלישי המאחסן אותם, יהיו חסינים באופן מוחלט מפני גישה בלתי מורשית למידע המאוחסן בהם.
        <br />
        <br />
        בגלישה באתר, בהרשמה ובהעלאת תוכן ו/או מידע, המשתמש פוטר את החברה ו/או מי מטעמם מאחריות לכל נזק שייגרם לו ו/או למי מטעמו עקב התקפות, ניסיונות פריצה וחדירות למידע כאמור ומוותרים על כל טענה כנגד החברה ו/או מי מטעמו בקשר לכך.
        <br />
        <br />
        החברה תוכל בכל רגע נתון וע"פ שיקול דעתה בלבד וללא צורך בהודעה, להסיר כל תוכן/תגובה שפורסמה ע"י משתמש באתר, לרבות מחיקת/השהיית המשתמש.
        <br />
        חלק מההודעות והפרסומים שנשלחים אליך, עשויים להכיל "עוגיות" כדוגמת "עוגיות זיהוי" על הדפדפן שלך.
        <br />
        החברה אינה שומרת את פרטי כרטיסי האשראי של המשתמשים בשרתי העסק או בכל מקום אחר.
      </p>
      <p>
        האתר משמש כמתווך בין ספקי צד ג'- יצרנים, מוכרים בסיטונאות, ומפעלים שונים מרחבי העולם, לבין לקוחות המשתמשים בשירותי האתר ו/או מבצעים הזמנת מוצר/ים באמצעות האתר (להלן:"המשתמש") של מוצרים לצורך יבוא אישי ולא מסחרי.
        <br />
        <br />
        משתמשים אשר רוכשים מוצרים/שירותים דרך אתר החברה, כפופים גם לתנאי השימוש של השותפים.
        <br />
        <br />
        כל הזמנה באתר כפופה לחוקי מדינת ישראל. כל משתמש באתר נדרש לבדוק את עלויות המיסוי, המכס, המע"מ ועלויות אחרות בטרם ביצוע הזמנה. למידע נוסף, מצ"ב לנוחיותכם קישור לאתר רשות המיסים, על ידי לחיצה על הקישור
        <br />
        <br />
        הזמנה באתר:
        לצד כל מוצר מופיע מחירו, כאשר המחיר כולל מס ערך מוסף (מע"מ) אלא אם צוין אחרת. עם זאת המפעילה רשאית לשנות את מחירו של כל מוצר בכל עת ועל פי שיקול דעתה הבלעדי מבלי שלמשתמש יהיו טענות נגדה בשל כך. יובהר כי המועד הקובע לעניין מחיר המוצר הוא מועד אישור ההזמנה על ידי המזמין ולא מועד הוספתו לסל הקניות.
        <br />
        <br />
        החברה היא לא היצרנית של המוצרים שנמכרים באתר.
        <br />
        למרות שאנו עובדים בכדי לוודא שהמידע על המוצר באתר שלנו הוא מדויק,
        יצרנים עשויים לא לדייק או לשנות את המידע שהם מחליטים לרשום בדף המוצר.
        אריזות המוצר והחומרים עשויים לכלול מידע נוסף או שונה מהמידע שמוצג בדפי המוצר באתר.
        למוצרים רפואיים או עם השלכות רפואיות- התוכן באתר שלנו אינו משמש לאבחנה, טיפול, ריפוי או מניעה של שום בעיה רפואית או אחרת.
        המידע אינו מחליף ייעוץ רפואי ואנו ממליצים להתייעץ עם רופא או איש מקצוע מוסמך לפני שימוש בכל מוצר שבא שמגע עם הגוף.
        עליכם תמיד לקרוא את המידע, אזהרות והנחיות שמצורפים למוצר לפני השימוש בו ולא להסתמך רק על המידע שנמצא באתר שלנו.
        אם אתם מרגישים שאחד או יותר מהמוצרים שנחשפתם אליהם באתר, הינו פוגעני, מטעה או לא חוקי, אנו מזמינים אתכם ליצור איתנו קשר בכדי שנוכל לטפל בנושא בהקדם האפשרי.
        <br />
        <br />
        כל המידע המוצג באתר לגבי מוצרים לרבות טיבם ואיכותם, התקבל ישירות מהשותפים.
        משתמש באתר, מסיר מהחברה אחריות לגבי אי-דיוקים/אי-התאמות בפרטים שמוצגים באתר ביחס למוצרים, לרבות תמונות ומפרטי מוצרים שהוצגו בדפי המכירה וכו’.
        <br />
        <br />
        בעת הזמנה באתר על המשתמש להזין את פרטיו(כגון: שם מלא, כתובת מגורים עדכנית, מספר טלפון, כתובת דוא"ל פעילה וכל מידע נוסף שידרש לטובת ביצוע ההזמנה).
        <br />
        <br />
        לאחר מכן חלק מהפרטים שהוזנו יועברו אל השותפים, לטובת ביצוע המשלוח. לכן חשוב מאוד למלא פרטים מדויקים ונכונים.
        הערה: ניתן לבצע הזמנות לכתובות מגורים בתוך מדינת ישראל בלבד.
        <br />
        <br />
        עד 48 שעות ממועד ביצוע ההזמנה, המשתמש יקבל בדואר האלקטרוני שמסר את אישור ההזמנה, אישור הזמנה זה מהווה אישור סופי ומוחלט.
        <br />
        <br />
        במידה ומוצר שהזמנת אינו נמצא במלאי, או שמסיבה כזאת או אחרת אין ביכולת הספק לספק את המוצר, תוצע לך אופציה לקבלת מוצר חלופי או ביטול העסקה.
        <br />
        <br />
        במידה ותחליט על ביטול – יבוצע זיכוי מלא בכרטיס האשראי שלך.
        הערה: למעט קבלת מוצר חלופי או ביטול עסקה כאמור, לא תהיה לך כל טענה, תביעה ו/או דרישה, כלפי החברה.
        <br />
        <br />
        הזמן עד קבלת הכסף באמצעי התשלום, לפעמים יכול להתעכב בשל גורמים חיצוניים ועלול להתעכב עד משך של 45 ימי עסקים.
        <br />
        <br />
        לאחר ביצוע הזמנה, יתבצע רישום אוטומטי למערכת הדיוור האלקטרוני של האתר.
        הרישום הכרחי על מנת לעדכן את הנרשם או הרוכש על שלבי הטיפול בהזמנה כולל משלוח חשבוניות, אישור הזמנה, מעקב משלוחים וכו’.
        <br />
        <br />
        כמו כן, הרישום לדיוור האלקטרוני יאפשר לאתר ולצוות של החברה לשלוח מידע פרסומי ושיווקי. אנו מתחייבים לא להעביר את פרטי הרישום לאף חברה אחרת.
        <br />
        <br />
        אפשר להתנתק מהדיוור האלקטרוני השיווקי על ידי לחיצה על קישור שנמצא בכל הודעה שיווקית שתשלח אליך. אין אפשרות להתנתק ממערכת ההודעות על מצב ההזמנה והמשלוחים.
        <br />
        <br />
        אין האתר אחראי על טיב המוצרים, זמני המשלוח (לרבות עיכובים הנובעים מגורמים שונים) או איחור באספקה וכל נזק שייגרם ללקוח בשל כך.
        <br />
        <br />
        הגבלת אחריות
        התמונות המופיעות באתר הינן לשם המחשה בלבד. ייתכנו הבדלי צבעים ושינויים אחרים בין מראה המוצר בתמונה לבין המוצר במציאות.
        <br />
        <br />
        העסק לא יהיה אחראי לטעויות קולמוס במפרטים והפרטים הטכניים של כל מוצר ומוצר. מוסכם כי הלקוח או צד שלישי מוותר בזאת על טענה ו/או תביעה בגין טעות קולמוס כאמור.
        <br />
        <br />
        האחריות לכל המוצרים המוצעים באתר חלה על היצרנים והספקים בלבד.
        <br />
        <br />
        מוסכם בזאת כי כל עיכוב או אי ביצוע של אספקת מוצר שנרכש באתר שנבעו מכוח עליון לרבות מזג אויר חריג, שביתות אצל נותני השירותים השונים או שיבושים בלתי צפויים במחשב האתר לא יחייבו את העסק, והלקוח או מי מטעמו או צד שלישי מוותרים בזאת על כל טענה ו/או תביעה בשל כך.
      </p>
      <p>
        ב. תנאים נוספים
        <br />
        העסק ומנהלי האתר לא יישאו באחריות עקיפה או ישירה כלפי המזמין או כלפי צד שלישי בכל מקרה של כשל בהזמנה או באספקה בשל כוח עליון לרבות תקלות במערכת המחשבים, הטלפון, בשל חברות האשראי או בשל כל גורם אחר הקשור בהשלמת פעולת הרכישה והאספקה.
        <br />
        מוסכם בזאת כי הזמנה ורכישה דרך האתר אינה ולא תהווה חוזה לטובת צד שלישי` אחר.
        <br />
        העסק שומר לעצמו את הזכות לשנות את התקנון מפעם לפעם עפ"י שיקול דעתו.
        <br />
        כל מידע ו/או תוכן ואו תמונות המופיעים באתר, לרבות גרפיקה, עיצוב, סימני מסחר, סימני לוגו לרבות עריכתם ואופן הצגתם באתר,הנם בבעלותם הבלעדית של האתר או העסק.
        <br />
        משלוחים
        <br />
        זמן המשלוח אורך בדר''כ עד 30 ימי עסקים. ממועד שליחת המוצר.
        <br />
        אספקה עד בית הלקוח פועלת במספר מצומצם של יישובים, ולכן המשלוחים יגיעו אל נקודת איסוף (לרבות סניפי דואר).
        מרגע ההזמנה, זמן השילוח העומד לחברה הינו 5 ימי עסקים.
        בחלק מהמקרים עלולים להיות עיכובים או שינויים בלתי צפויים, כמו למשל עיכובים מצד דואר ישראל, חברות השילוח או הגורמים השונים בתהליך.
        המועד המאוחר ביותר לאספקת המוצר/ים הוא לכל היותר 60 ימי עסקים ממועד שליחת המוצר.
        במקרה כזה יש ליצור קשר עם שירות הלקוחות של החנות.
        <br />
        שימו לב: ניתן לבצע משלוח ישיר לישוב המקומי בתשלום נוסף
        <br />
        החזרות וביטולים
        <br />
        ניתן לבצע ביטול של הזמנה עד 14 ימי עסקים ממועד ההזמנה עצמה.
        <br />
        ביטול עסקה יש לבצע באמצעות פנייה לשירות הלקוחות באמצעות טופס ההתחברות.
        <br />
        כל סכום ההחזר יוחזר לך דרך אמצעי התשלום אותם בחרת בביצוע ההזמנה ולא יחוייב בכל שורת עמלה או תשלום נוסף.
        <br />
        בכל מקרה של ביטול יש להחזיר את המוצר תוך 14 ימי עסקים ממועד הודעת הביטול, ובמקרה של עזרה מקצועית יש לפנות לפנייה האפשרות למערך דואר הלקוחות.
      </p>
    </div>
  );
}

export default Terms;
