import React from 'react';

function About() {
  return (
    <div style={styles.container}>
      <p style={styles.text}>קצת עלינו</p>
      <br/>
      <p style={styles.text}>רנסנס היא חברת בגדים צעירה חדשנית ותוססת, המשלבת סטייל ויחודיות עם יופי ואיכות</p>
      <br/>
      <p style={styles.text}> מטרתנו להביא אתכם למרכז הבמה, לאפשר לכם לבלוט ולהביע את עצמכם בעזרת הלבוש</p>   
      <br/>
      <p style={styles.text}> החברה הוקמה בשנת 2023 עם חזון מאוד ברור</p>  
      <br/>
      <p style={styles.text}>להביא משהו חדש, תוסס וצעיר, אופנת רחוב עם ייחודיות יוצאת דופן </p>  
      <br/> 
      <p style={styles.text}>אנחנו מאמינים שכל אדם יכול להביע את עצמו על ידי הלבוש שלו, לשבור את החומות ולהגיע למטרה שלו </p>  
      <br/> 
      <p style={styles.text}>אנחנו כאן כדי לקחת חלק בתהליך שלכם ולהיות נוכחים ברגעים הכי חשובים לאורך כל הדרך </p>
      <br/> 
      <p style={styles.text}>RENAISSANCE</p>   
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: 'black',
    color: 'white',
    fontFamily: "'Amatic SC', sans-serif",
    paddingTop: '100px',
    // display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    alignItems: 'center', // Center vertically
  },
  text: {
    margin: 10,
    fontFamily: "'Amatic SC', sans-serif",
    fontSize: '2em', 
    textAlign: 'center', // Center horizontally

   },
};



export default About;
