import axios from "axios";
import { SERVER_URL } from "./config";

export async function getLoginToken(username, password) {
    
try {
    const res = await axios.post(SERVER_URL+'get-token', {
        username: username,
        password: password,
    });

    if (res.status === 200) {
        const token = res.data.token;
        localStorage.setItem('token', token);
        return token;
    } else {
        return false;
      }
    } catch (error) {
        return false
    }
}

    export async function validateToken() {
        const token = localStorage.getItem('token');
        try {
            const res = await axios.get(SERVER_URL+'user_by_token', {
                headers: {Authorization: `token ${token}`},
            });
            if (res.data.superuser) {
                localStorage.setItem('issuperuser', res.data.superuser);
            } else {
                localStorage.setItem('issuperuser', false);
            }
            if (res.status === 200) {
                return res.data;
            } else {
                return false;
            }
            } catch (error) {
                return false
            }
        }
