import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';



// Include the font links
const link1 = document.createElement('link');
link1.rel = 'preconnect';
link1.href = 'https://fonts.googleapis.com';
document.head.appendChild(link1);

const link2 = document.createElement('link');
link2.rel = 'preconnect';
link2.href = 'https://fonts.gstatic.com';
link2.crossOrigin = true;
document.head.appendChild(link2);

const fontLink = document.createElement('link');
fontLink.href = 'https://fonts.googleapis.com/css2?family=Amatic+SC&family=Rowdies&display=swap';
fontLink.rel = 'stylesheet';
document.head.appendChild(fontLink);

// Create a root for ReactDOM
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the React app
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// Report Web Vitals
reportWebVitals();
