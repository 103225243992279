const SERVER =
  window.location.host === 'localhost:3000'
    ? 'http://127.0.0.1:8000'
    : 'https://renaissance-il.com';

export const SERVER_URL = `${SERVER}/api/v1/`;

export const PAY = 'ASGj1vO5vjt8jWQN70mSxs63YXLd7he3zWHbpawUeS7y9LnJxSC3fl2UoMi52ycqRPz5CfM6dh1rh7Ag'


