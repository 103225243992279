// PopupModal.jsx
import React, { useContext, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { AppContext } from '../App'; // Adjust the path as needed
import './Popup.css'; // Import the CSS file

const PopupModal = () => {
  const { showModal, handleCloseModal } = useContext(AppContext);


  
  return (
    <Modal show={showModal} onHide={handleCloseModal} dialogClassName="popup-modal" centered>
      <Modal.Body className="text-center">
        <div className="popup-content">
        <button className="close" onClick={handleCloseModal}>
          X
            </button>
                <div className="popup-text">
            <h1>הנחה 10%</h1>
            <h4>בקנייה ראשונה למצטרפים חדשים</h4>
            <br/>
            <a href="/signup" className="signup-button">להרשמה</a>
          </div>
          <div className="popup-image">
            <img src={"https://renaissance-app.s3.eu-central-1.amazonaws.com/home/popup"} alt="Popup" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PopupModal;
