import React, { useState } from 'react';

const CouponPopup = ({ onClose, onApplyCoupon }) => {
  const [coupon, setCoupon] = useState('');

  const handleApply = () => {
    onApplyCoupon(coupon);
  };

  return (
    <div style={{ padding: '20px', border: '1px solid #ccc', backgroundColor: '#fff', textAlign: 'right' }}>
      <h3>יש לך קוד קופון</h3>
      <input
        type="text"
        placeholder="קוד קופון"
        value={coupon}
        onChange={(e) => setCoupon(e.target.value)}
      />
      <button onClick={handleApply}>הוסף את הקופון שלי</button>
      <br/>
      <button onClick={onClose}>אין ברשותי קוד קופון</button>
    </div>
  );
};

export default CouponPopup;
