import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingCart, faUser, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import './NavBar.css';
import { AppContext } from './App';

const Navbar = () => {
  const location = useLocation();
  const { isCartOpen, setIsCartOpen, user, setUser, isWishListOpen, setIsWishListOpen } = useContext(AppContext);
  const navbarClass = 'transparent-navbar'; // or 'normal-navbar'
  const navbarClassName = location.pathname === '/' ? navbarClass : 'normal-navbar';
  const navigate = useNavigate();

  const handleCartClick = () => {
    setIsCartOpen(!isCartOpen);
  };

  const handleListClick = () => {
    setIsWishListOpen(!isWishListOpen);
  };

  const handleLogout = () => {
    setUser(null);
  };

  const handleLinkClick = () => {
    const checkbox = document.querySelector('.checkbox');
    if (checkbox) {
      checkbox.checked = false; // Uncheck the checkbox
    }
  };

  return (
    <>
      <nav className={navbarClassName}>
        <div className="navbar">
          <div className="nav-container">
            <div className="logo">
              <NavLink to="/" onClick={handleLinkClick}>
                <h1>RENAISSANCE</h1>
              </NavLink>
            </div>
            <input className="checkbox" type="checkbox" name="" id="" />
            <div className="hamburger-lines">
              <span className="line line1"></span>
              <span className="line line2"></span>
              <span className="line line3"></span>
            </div>
            <div className="shopping-cart-icon" onClick={handleCartClick}>
              <FontAwesomeIcon icon={faShoppingCart} />
            </div>
            <div className="wish-list-icon" onClick={handleListClick}>
              <FontAwesomeIcon icon={faHeart} />
            </div>
            <div className="contact-icon" onClick={user ? handleLogout : () => navigate('/login')}>
              {user ? <FontAwesomeIcon icon={faUserSlash} /> : <FontAwesomeIcon icon={faUser} />}
            </div>
            <ul className={'menu-items'}>
              <li>
                <NavLink to="/" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  דף הבית
                </NavLink>
              </li>
              <li>
                <NavLink to="/about" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  אודות
                </NavLink>
              </li>
              <li>
                <NavLink to="/t-shirt" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  חולצות
                </NavLink>
              </li>
              <li>
                <NavLink to="/jaket" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  ג'קטים
                </NavLink>
              </li>
              <li>
                <NavLink to="/pants" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  מכנסיים
                </NavLink>
              </li>
              <li>
                <NavLink to="/shoes" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  נעליים
                </NavLink>
              </li>
              <li>
                <NavLink to="/class" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  מכופתרות
                </NavLink>
              </li>
              <li>
                <NavLink to="/hats" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  כובעים
                </NavLink>
              </li>
              <li>
                <NavLink to="/exesories" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  אקססוריז
                </NavLink>
              </li>
              <li>
                <NavLink to="/sale" className={({ isActive }) => isActive ? "active" : ""} onClick={handleLinkClick}>
                  ~sale~
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
