import { AppContext } from '../App'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useContext, useState } from "react";
import { PAY, SERVER_URL } from "./config";
import axios from "axios";
import { useLocation, useNavigate } from 'react-router-dom';

function FinishPay() {
    const {totalPrice, addToCart, setTotalPrice, setAddToCart, user, add} = useContext(AppContext)
    const [fundingSource, setFundingSource] = useState(null);
    const location = useLocation();
    const formData = location.state.formData;
    const navigate = useNavigate()

    
    const PayPalButton = () => {
    
      const createOrder = (data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: "ILS",
                value: totalPrice+add,
              },
            },
          ]
        });
      };
    
      const onApprove = async (data, actions) => {
        try {
          const details = await actions.order.capture();
          setTotalPrice(0)
          setAddToCart([])
          const response = await axios.post(SERVER_URL + 'new_order', { message: formData, items: addToCart, user: user ? user.id : null });
        } catch (error) {
          console.error('Error during payment or sending message', error);
        }
        alert("תשלום בוצע בהצלחה, מיד תועבר בחזרה לאתר");
        navigate('/')

      };
      
      
    
      const clientId = PAY;
    
      return (
        <>
        <h1 style={{ textAlign: "center", fontFamily: "'Varela Round', sans-serif" }}>
          בחר אמצעי תשלום
        </h1>
          <br/>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PayPalScriptProvider
              options={{
                "client-id": clientId,
                components: "buttons,applepay",
                currency: "ILS",
                locale: "he_IL",
                intent: "capture",
              }}
            >
              <PayPalButtons
                style={{ color: "black" }}
                createOrder={createOrder}
                onApprove={onApprove}
                components={{
                  applePay: true,
                }}
              >

              </PayPalButtons>
            </PayPalScriptProvider>
          </div>
        </>
      );
    };
  
    return (
      <div style={{ marginTop: "100px", textAlign: "center", fontSize:'10px' }}>
<h1 style={{ fontFamily: "'Varela Round', sans-serif" }}>
        מחיר סופי {totalPrice+add} ש"ח
      </h1>
          <br/>
        <PayPalButton />
      </div>
    );
  }
  
  export default FinishPay; 