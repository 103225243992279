import ContactComponent from './components/ContactComponent';
import './App.css';
import './components/Popup.css';
import { createContext, useEffect, useState } from 'react';
import Navbar from './NavBar';
import SiteRoutes from './components/SiteRoutes';
import React from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import PopupModal from './components/PopupModal';
import Cart from './components/Cart';
import axios from 'axios';
import { PAY, SERVER_URL } from './components/config';
import WishList from './components/WishList';



export const AppContext = createContext(null)

const App = () => {
  const loadState = (key, defaultValue) => {
    const storedValue = localStorage.getItem(key);
    try {
      return storedValue ? JSON.parse(storedValue) : defaultValue;
    } catch (error) {
      console.error(`Error parsing JSON for key '${key}':`, error);
      return defaultValue;
    }
  };
  
  const saveState = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const [selectedItem, setSelectedItem] = useState(loadState('selectedItem', ''));
  const [addToCart, setAddToCart] = useState(loadState('addToCart', []));
  const [addToWishList, setAddToWishList] = useState(loadState('addToWishList', []));
  const [showModal, setShowModal] = useState(false);
  const [totalPrice, setTotalPrice] = useState(loadState('totalPrice', ''));
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isWishListOpen, setIsWishListOpen] = useState(false)
  const [user, setUser] = useState(loadState('user', []));
  const [isLogged, setIsLogged] = useState(loadState('isLogged', false));
  const [recommendedItems, setRecommendedItems] = useState([])
  const [recommendedImages, setRecommendedImages] = useState([])
  const [clothes, setClothes] = useState(loadState('clothes', []));
  const [selectedColors, setSelectedColors] = useState(loadState('selectedColors', []));
  const [selectedSizes, setSelectedSizes] = useState(loadState('selectedSizes', []));
  const [add, setAdd] = useState(loadState('totalPrice', 0));





  const get_recommended = async () => {
    try {
      const responseItems = await axios.get(`${SERVER_URL}get_recommended`);
      setRecommendedItems(responseItems.data);

    } catch (error) {
      console.error('Error getting recommended items', error);
    }
  };

  useEffect(() => {
    // Save state to localStorage whenever it changes
    saveState('selectedItem', selectedItem);
    saveState('addToCart', addToCart);
    saveState('addToWishList', addToWishList);
    saveState('totalPrice', totalPrice);
    saveState('user', user);
    saveState('isLogged', isLogged);
    saveState('clothes', clothes);
    saveState('selectedColors', selectedColors);
    saveState('selectedSizes', selectedSizes);
    saveState('add', add);

    document.cookie = 'myCookie=myValue; SameSite=None; Secure';

    
    const fetchData = async () => {
      await get_recommended();
    };

    fetchData();

    // const contactModalShown = localStorage.getItem('contactModalShown');
    const modalShownInSession = sessionStorage.getItem('modalShownInSession');

    if (!modalShownInSession && !isLogged) {
      const timer = setTimeout(() => {
        setShowModal(true);
        sessionStorage.setItem('modalShownInSession', 'true');
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [selectedItem, addToCart, addToWishList, totalPrice, user, isLogged, clothes, selectedColors, selectedSizes, add]);
  const handleCloseModal = () => {
    setShowModal(false);
  };


return (
      <div className="app-container">
  <PayPalScriptProvider options={{ 'client-id': PAY }}>

    <AppContext.Provider value={{selectedItem, setSelectedItem, addToCart, setAddToCart, 
      showModal, handleCloseModal, totalPrice, setTotalPrice, isCartOpen, setIsCartOpen, 
      user, setUser, isLogged, setIsLogged, recommendedItems, setRecommendedItems, recommendedImages, setRecommendedImages,
      addToWishList, setAddToWishList, isWishListOpen, setIsWishListOpen, clothes, setClothes, selectedColors, setSelectedColors, selectedSizes, setSelectedSizes, add, setAdd}}>

      <Navbar />
      <div className="content-container">
      <SiteRoutes />
      <ContactComponent />
     </div>
     <PopupModal open={showModal} onClose={handleCloseModal} />
     {isCartOpen && <Cart />}
     {isWishListOpen && <WishList />}
        </AppContext.Provider>
      </PayPalScriptProvider>
      </div>
    );
};

export default App;